'use strict';

angular.module('kljDigitalLibraryApp')
    .service('SupportService', ["common", "appConfig",
        function(common, appConfig) {

            this.createFeedback = function(body) {
                let feedback = common.callApi('POST', '/api/feedback/create/', appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE_JSON, body);
                return feedback;
            }
        }
    ]);